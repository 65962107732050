import gql from 'graphql-tag'
import testFagment from './TestFragment'
import testPayFagment from './TestPayFragment'

export const ENTITIES_QUERY = gql`
  query Entities {
    entities: entities {
      id
      name
    }
  }
`
export const GET_TESTS_PARTNER_QUERY = gql`
  query GetTestsPartner($filterTest: FilterTest) {
    tests: getTestsPartner(filterTest: $filterTest) {
      tests {
        ...${testFagment}
      }
      totalDocs
      totalPages
    }
  }
`
export const GET_ENTITY_CHATS = gql`
  query getReciveMessagePartner {
    chatEntities:getReciveMessagePartner{
      participant{
        entity{
          id
          name
        }
      }
    }
  }
`

export const GET_TESTS_PAYMENT = gql`
  query getPaymentTestsPartner {
    payment: getPaymentTestsPartner {
      id
      cod
      tests{
        test{...${testPayFagment}}
        paid
        toPay
        toConfirmed
        status
        partner
      }
      candidates{
        id
      }
      entity{
        id
        name
      }
      vacancy{
        id 
        title
      }
      status
      amount
      date
      time
      createdAt
      updatedAt
    }
  }
`